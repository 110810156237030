<template>
  <div>    
    <b-card bg-variant="success" class="text-center" v-if="submitted">
      <b-button
      variant="outline-success"
      :to="{ name: 'demo-mio-tabella-test1'}"
      >
          <feather-icon
              icon="CornerUpLeftIcon"
              size="16"
              class="mr-1"
          />Ultimi utenti caricati
      </b-button>
    </b-card>

    <div>
        <b-overlay
        :show="inCaricamento"
        spinner-variant="primary"
        rounded="sm"
        >
        <div>

            <b-card bg-variant="Default" title="Modifica dati utente">

            <validation-observer ref="simpleRules">
                <b-form>
                <b-row>
                    <b-col md="6">
                    <b-form-group label="Nome e Cognome" label-for="nomeecognome">
                        <validation-provider
                        #default="{ errors }"
                        name="Nome e Cognome"
                        rules="required"
                        >
                        <b-form-input
                            v-model="campiform.nomeecognome"
                            :state="errors.length > 0 ? false:null"
                            placeholder="Nome e Cognome"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    </b-col>
                    <b-col md="6">
                    <b-form-group label="Email" label-for="email">
                        <validation-provider
                        #default="{ errors }"
                        name="Email"
                        rules="required|email"
                        >
                        <b-form-input
                            v-model="campiform.emailValue"
                            :state="errors.length > 0 ? false:null"
                            type="email"
                            placeholder="Email"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    </b-col>

                    <b-col md="6">
                    <b-form-group label="Start data" label-for="startdata">
                        <validation-provider
                        #default="{ errors }"
                        name="Start data"
                        rules="required"
                        >
                        <b-form-input
                            v-model="campiform.startdata"
                            :state="errors.length > 0 ? false:null"
                            placeholder="Datatime inizio lavoro"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    </b-col>
                    <b-col md="6">
                    <b-form-group label="Salario" label-for="salario">
                        <validation-provider
                        #default="{ errors }"
                        name="Salario"
                        rules="required"
                        >

                        <b-input-group
                            prepend="€"
                            append=".00"
                            class="input-group-merge"
                        >
                            <b-form-input
                            v-model="campiform.salario"
                            :state="errors.length > 0 ? false:null"
                            placeholder="100"
                            />
                        </b-input-group>

                        
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    </b-col>
                    <b-col md="6">
                    <b-form-group label="Status (mansione)" label-for="status">
                        <validation-provider
                        #default="{ errors }"
                        name="Status"
                        rules="required"
                        >
                        <b-form-select
                            v-model="campiform.selected"
                            :options="options"
                            :state="errors.length > 0 ? false:null"
                        />
                        </validation-provider>
                    </b-form-group>
                    </b-col>
                    
                    

                    <!-- submit button -->
                    <b-col md="12" class="text-center">
                    <b-button
                        variant="primary"
                        type="submit"
                        @click.prevent="validationForm"
                    >
                        Salva
                    </b-button>
                    </b-col>
                </b-row>
                </b-form>
            </validation-observer>

            </b-card>
        
        </div>
        </b-overlay>
    </div>

  </div>
</template>

<script>
import router from '@/router'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BOverlay, BCard, BInputGroup, BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BFormSelect, } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  required, email
} from '@validations'

export default {
  components: {
    BOverlay,
    BCard,
    ValidationProvider,
    ValidationObserver,
    BInputGroup,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      campiform: {
        emailValue: '',
        nomeecognome: '',
        startdata: '',
        salario: '',
        selected: null,
      },
      required,
      email,
      options: [
        { value: null, text: 'Seleziona una voce' },
        { value: 'Professional', text: 'Professional' },
        { value: 'CTO', text: 'CTO' },
        { value: 'Junior Developer', text: 'Junior Developer' },
        { value: 'Senior Developer', text: 'Senior Developer' },
        { value: 'd', text: 'voce non selezionabile ... (disabled)', disabled: true },
      ],
      inCaricamento: true,
      submitted: false,
      show: true,
    }
  },
  created() {
    //console.log('id (speriamo bene) -> '+router.currentRoute.params.id);
    
    this.$http.get('v2/democrud/singolo/'+router.currentRoute.params.id)
    .then(response => {
        console.log("risposta ???"); 
        //console.log(response.data);
        //console.log("----------------------------------------") 
        console.dir(response);
        console.log("risposta server -> email -> "+response.data.dati.emailValue)


        this.campiform = { emailValue: response.data.dati.emailValue,
        nomeecognome: response.data.dati.nomeecognome,
        startdata: response.data.dati.startdata,
        salario: response.data.dati.salario,
        selected: response.data.dati.selected, };

        this.inCaricamento = false;
    })
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          console.log('start invio form ... spriamo bene')
          console.log('Nome e Congome -> '+this.campiform.nomeecognome)
          //console.log(this.campiform)

          //aggiungi ID al post in partenza
          this.campiform = Object.assign({}, this.campiform, {id: router.currentRoute.params.id});
          
          this.$http.get('v2/democrud/salvaedit', {
            params: this.campiform 
            }).then(response => { 
              console.log("risposta server --------")
              console.log(response.data)

              if(response.data.statusCode===200){
                //risposta positiva
                this.showToast('success','CheckCircleIcon',response.data.success.descrizione,'','top-center')
                this.submitted = true;
              } else {
                //risposta negativa (errore sul server)
                this.showToast('danger','AlertCircleIcon',response.data.error.descrizione,'','top-center')
                this.submitted = false;
              }
            }).catch(e => {
              console.log(e);
            });
          
        }
      })
    },
    showToast(variant, icona, titolo, testo, position) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: titolo,
          icon: icona,
          text: testo,
          variant,
        },
      },
      {
        position,
      })
    },
  },
}
</script>
